import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import VCenter from 'components/vcenter-view';

const LabelValueHorizontalIntl = ({data, intl}) => (
  <VCenter>
    <ul className="list-inline no-margin">
      {data.icon &&
        <li>
          <i className={`togo-icon ${data.icon}`} />
        </li>}
      <li>
        <strong>{data.value}</strong>
      </li>
      <li className="value-label">
        {(data.label.hasOwnProperty('defaultMessage')) ? intl.formatMessage(data.label) : data.label}
      </li>
    </ul>
  </VCenter>
);

LabelValueHorizontalIntl.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string,
    value: PropTypes.any.isRequired,
    label: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired,
      }),
      PropTypes.node,
    ]),
  }),
  intl: PropTypes.object.isRequired,
};

export default injectIntl(LabelValueHorizontalIntl);
