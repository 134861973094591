import axios from 'axios';
import Session from 'lib/session';

export const set = (config) => Session.setItem('environment', JSON.stringify(config));

export const fetch = () =>
  axios.get('/config/config.json')
    .then((response) => {
      set(response.data);
      return response.data;
    });

export const get = () => {
  const config = JSON.parse(Session.getItem('environment'));
  if (!config) {
    return {};
  }
  return config;
};

export default {
  set,
  get,
  fetch,
};
