import React from "react";
import PropTypes from "prop-types";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import commonClasses from "styles/_base.scss";
import BoxedContainer from "components/boxed-container-view";
import st from "core/shared-translations";
import {
  EditOutlinedIcon,
  InfoOutlinedIcon,
} from "@getgo/chameleon-icons/react";
import { Alert, ButtonLink } from "@getgo/chameleon-web-react-wrapper";
import ResponsiveDialog from 'components/dialog-view.js';
import { EmailOutlinedIcon } from "@getgo/chameleon-icons/react";

const t = defineMessages({
  "email-subscription-opt-out.email-subscription": {
    id: "email-subscription-opt-out.email-subscription",
    defaultMessage: "Email Subscription",
    description: "Title string for the Email Subscription section",
  },
  "email-subscription-opt-out.email-subscription-body": {
    id: "email-subscription-opt-out.email-subscription-body",
    defaultMessage:
      "Email updates keep you informed on the latest and greatest products and features.",
    description: "Body text for the email subscription section",
  },
  "email-subscription-opt-out.opt-out-link": {
    id: "email-subscription-opt-out.opt-out-link",
    defaultMessage: "Unsubscribe from email updates",
    description: "Text for the unsubscribe link",
  },
});

export const EmailSubscriptionOptOut = ({
  marketingOptOutUrl,
  isViewOnly = false,
  onEmailSubscriptionClick,
  closeModal,
  modalIsOpen
}) => {
  const intl = useIntl();
  return (
    <div className={commonClasses["singleColumn"]}>
      <BoxedContainer
        title={
          <FormattedMessage
            {...t["email-subscription-opt-out.email-subscription"]}
          />
        }
        buttonIcon={<EditOutlinedIcon />}
        buttonText={<FormattedMessage {...st["shared.edit"]} />}
        showCTAButton={false}
        onClick={onEmailSubscriptionClick}
        boxIcon={<EmailOutlinedIcon color="#000000" />}
      >
        <Alert
          icon={<InfoOutlinedIcon color="var(--goto-icon-color-brand-default)" />}
          className={commonClasses['info-state']}
        >
          <FormattedMessage
            {...t["email-subscription-opt-out.email-subscription-body"]}
          />
        </Alert>
        <ButtonLink
          href={marketingOptOutUrl}
          target="_blank"
          disabled={isViewOnly}
          title={intl.formatMessage(t["email-subscription-opt-out.opt-out-link"])}
          variant="secondary"
          style={{ marginTop: "22px" }}
        >
          <FormattedMessage {...t["email-subscription-opt-out.opt-out-link"]} />
        </ButtonLink>
      </BoxedContainer>

      <ResponsiveDialog
        open={modalIsOpen}
        title={
          <FormattedMessage {...t["email-subscription-opt-out.email-subscription"]} />
        }
        closable
        onClosed={closeModal}
      >
        <Alert
          icon={<InfoOutlinedIcon color="var(--goto-icon-color-brand-default)" />}
          className={commonClasses['info-state']}
        >
          <FormattedMessage
            {...t["email-subscription-opt-out.email-subscription-body"]}
          />
        </Alert>
        <ButtonLink
          href={marketingOptOutUrl}
          target="_blank"
          disabled={isViewOnly}
          title={intl.formatMessage(t["email-subscription-opt-out.opt-out-link"])}
          variant="secondary"
          style={{ marginTop: "22px" }}
        >
          <FormattedMessage {...t["email-subscription-opt-out.opt-out-link"]} />
        </ButtonLink>
      </ResponsiveDialog>
    </div>
  );
};

EmailSubscriptionOptOut.propTypes = {
  marketingOptOutUrl: PropTypes.string.isRequired,
  isViewOnly: PropTypes.bool,
  onEmailSubscriptionClick: PropTypes.func,
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func
};

export default EmailSubscriptionOptOut;
