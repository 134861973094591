import React from "react";
//import ReactDOM from 'react-dom';
import createStore from "store/create-store";
import initialState from "store/initial-state";
import AppContainer from "core/containers/app-container";
import profileAuth from "lib/profile-auth";
import {
  meLocale,
  meRequestGet,
  meIsOrganizationMember,
  meId,
} from "modules/me-module";
import { orgServiceMeRequestGet } from "modules/organization-module";
import { securityCheck } from "lib/security-check";
import { createRoot } from "react-dom/client";
// ========================================================
// Store and History Instantiation
// ========================================================
// Create redux store and sync with react-router-redux. We have installed the
// react-router-redux reducer under the routerKey "router" in src/routes/index.js,
// so we need to provide a custom `selectLocationState` to inform
// react-router-redux of its location.
const store = createStore(initialState);

// ========================================================
// Developer Tools Setup
// ========================================================
if (__DEV__) {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    // @NOTE commented this out because we found the auto-opening of the devtools
    //       in a new window upon any reload/refresh to be very annoying;
    // window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__.open()
  }
}

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById("root");
const root = createRoot(MOUNT_NODE);

let render = (authorized) => {
  if (authorized) {
    // Get logged in user, switch to proper locale while fetching full profile account, then render the App
    store
      .dispatch(meRequestGet())
      .then(() => {
        securityCheck(store);
        const isOrganizationMember = meIsOrganizationMember(store.getState());
        const promises = [];
        if (isOrganizationMember) {
          promises.push(store.dispatch(orgServiceMeRequestGet()));
        }

        return Promise.all(promises);
      })
      .then(() => {
        const locale = meLocale(store.getState());
        const accKey = meId(store.getState());
        root.render(<AppContainer locale={locale} store={store} authorized={authorized} accountKey={accKey} />);
      });
  } else {
    root.render(<AppContainer locale="en_US" store={store} />);
  }
};

// ========================================================
// HMR (Hot Module Rendering) Setup
// ========================================================
if (__DEV__) {
  // This code is excluded from production bundle
  if (module.hot) {
    // Development render functions
    const renderApp = render;
    const renderError = (error) => {
      const RedBox = require("redbox-react").default;
      root.render(<RedBox error={error} />);
    };

    // Wrap render in try/catch
    render = (authorized) => {
      try {
        renderApp(authorized);
      } catch (error) {
        renderError(error);
      }
    };

    // Setup hot module replacement
    module.hot.accept(["./routes/index"], () => {
      setTimeout(() => {
        root.unmountComponentAtNode();
        render();
      });
    });
  }
}

const runApp = () => {
  // ========================================================
  // Go!
  // @NOTE We need runApp so we can separate profileAuth.init() from render(), since we only
  // want to call profileAuth.init() once, but HMR may call render() many times
  // ========================================================
  profileAuth.init(store).then(
    () => {
      render(true);
    },
    (ex) => {
      if (ex === "userinvitation") {
        render(false);
        return true;
      }
      render(true);
    }
  );
};

runApp();
