/*
  Define custom application exceptions
 */

/**
 * Extends Error base class
 */
export class ExtendableError extends Error {
  constructor(message) {
    super(message);
    if (typeof Error.captureStackTrace === 'function') {
      // creates a `.stack` property on `this` and will modify the first line of the stack to not include the error type and message
      // the second argument will cause all frames above and including this.constructor to be omitted from the stack trace.
      Error.captureStackTrace(this, this.constructor);
    } else {
      // if we dont have captureStackTrace (node), just return the normal error stack.
      this.stack = (new Error(message)).stack;
    }
  }
}

export class RouteNotFoundException extends ExtendableError {
  constructor(routeName) {
    super(`'${routeName}' route not found`);
    this.name = 'RouteNotFoundException';
  }
}

export default {
  RouteNotFoundException,
};
