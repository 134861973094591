import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import EmailVerificationEditor from "../components/email-verification-view";
import { hasIllegalCharacters, numbersOnly } from "core/input-validations";
import { uiShowToastrMessage } from "modules/ui-module";
import { meData, meIsLoading, meRequestPatchWithAuth } from "modules/me-module";
import {
  identityEmailData,
  identityEmailToken,
  emailVerificationCompletionPost,
  emailVerificationStatusGet,
} from "modules/identity-module";
import {
  setHandledErrorCodes,
  removeHandledErrorCodes,
} from "lib/error-message-handling";
import { stopEvent } from "lib/utility-functions";


const INVALID_TOKEN_CODE = "invalid.token.code";
const EXPIRED_TOKEN = "token.expired";
const VERIFIED = "VERIFIED";

export const EmailVerificationContainer = ({
  personData,
  showEmailEditor,
  userName,
  handlePrimaryEmailVerification,
  closeModal,
  completeProcess,
}) => {
  const { person, isLoading, emailData, emailToken } =
    useSelector(getValuesFromStore);
  let [allValues, setAllValues] = useState({
    personData: Object.assign({}, person),
    errors: {},
    isDirty: false,
    modalIsOpen: false,
    domainValue: "",
    domainUserName: "",
    verificationCode: "",
  });
  const dispatch = useDispatch();

  const validateUserInput = (field, value) => {
    let errorText = "";
    const errors = { ...allValues.errors };
    if (errors[field]) {
      delete errors[field];
    }
    if (value.length > 6) {
      return false;
    }
    if (value.length <= 6) {
      if (hasIllegalCharacters(value)) {
        errorText = "shared.illegal-character-entry";
      }
      if (!errorText.length && value.length && !numbersOnly(value)) {
        errorText = "shared.email-verification.number-entry-validation-failure";
      }
    }
    if (errorText.length) {
      errors[field] = errorText;
    }

    setAllValues((prevState) => ({
      ...prevState,
      errors: { ...errors },
    }));
    return true;
  };

  const onBlur = (e) => {
    const field = (e.target.name || e.target.id).split("-")[0];
    const value = e.target.value;
    return validateUserInput(field, value);
  };

  const onChange = (e) => {
    const field = (e.target.name || e.target.id).split("-")[0];
    const value = e.target.value;
    if (validateUserInput(field, value)) {
      setAllValues((prevState) => ({
        ...prevState,
        verificationCode: value,
        isDirty: true,
      }));
      return true;
    }

    setAllValues((prevState) => ({
      ...prevState,
      isDirty: true,
    }));
    return false;
  };

  const displayToastrMessage = (
    toastrType,
    toastrMessageId,
    toastrParams = null
  ) => {
    dispatch(uiShowToastrMessage(toastrType, toastrMessageId, toastrParams));
  };

  const getErrorCodesArray = () => {
    return ["user.username.conflict", INVALID_TOKEN_CODE, EXPIRED_TOKEN];
  };

  const onConfirm = (e) => {
    stopEvent(e);

    // Define the expected error code for user conflict to the UI-level handled error codes so no redirection will
    // occur to the error page upon receiving this exception
    setHandledErrorCodes(getErrorCodesArray());

    // We need to submit the user-entered key for email validation.
    // Then we need to query SCIM for a email validation pass/fail condition
    // If the email validation succeeds we need to close the modal dialog and notify the user through toastr.
    // We could use Promise.All here, but we need to do specific error handling and branching at each potential success/failure point.
    return dispatch(
      emailVerificationCompletionPost(emailToken, {
        code: allValues.verificationCode,
      })
    ).then(
      () => {
        dispatch(emailVerificationStatusGet(emailToken)).then(
          (response) => {
            if (response.payload && response.payload.status === VERIFIED) {
              dispatch(
                meRequestPatchWithAuth(`Email ${emailToken}`, {
                  userName: emailData.email || "",
                })
              ).then(
                () => {
                  displayToastrMessage(
                    "success",
                    "email-verification.email-change-successful"
                  );
                  // Let the toastr message display before we remove the modal component.
                  setTimeout(() => {
                    completeProcess();
                  }, 1000);
                  // IE does not support ".finally()" at the end of the promise chain,
                  // so we need to trigger the error code removal for success
                  removeHandledErrorCodes(getErrorCodesArray());
                },
                () => {
                  displayToastrMessage(
                    "error",
                    "email-verification.email-change-failed",
                    { email: userName }
                  );
                  // IE does not support ".finally()" at the end of the promise chain,
                  // so we need to trigger the error code removal for failure also
                  removeHandledErrorCodes(getErrorCodesArray());
                  closeModal();
                }
              );
            } else {
              removeHandledErrorCodes(getErrorCodesArray());
            }
          },
          () => {
            removeHandledErrorCodes(getErrorCodesArray());
            displayToastrMessage(
              "error",
              "email-verification.email-confirmation-failed"
            );
          }
        );
      },
      (ex) => {
        removeHandledErrorCodes(getErrorCodesArray());
        if (ex && ex.status && ex.status === 422) {
          const errCode = ex.data && ex.data.code ? ex.data.code : undefined;
          const errors = { ...allValues.errors };
          if (errCode === INVALID_TOKEN_CODE) {
            errors.verificationCode =
              "shared.email-verification.entry-validation-failure";
            setAllValues((prevState) => ({
              ...prevState,
              errors: { ...errors },
            }));
            return true;
          } else if (errCode === EXPIRED_TOKEN) {
            handlePrimaryEmailVerification(undefined, false);
            errors.verificationCode =
              "shared.email-verification.token-timeout-failure";
            setAllValues((prevState) => ({
              ...prevState,
              errors: { ...errors },
            }));
            return true;
          }
        }
        displayToastrMessage(
          "error",
          "email-verification.email-confirmation-failed"
        );
      }
    );
  };

  const enableSubmit = () => {
    return (
      Object.keys(allValues.errors).length === 0 &&
      allValues.isDirty &&
      allValues.verificationCode.length === 6 &&
      !isLoading &&
      allValues.personData.userName.length > 0
    );
  };

  return (
    <EmailVerificationEditor
      personData={allValues.personData}
      showEmailEditor={showEmailEditor}
      onBlur={onBlur}
      onChange={onChange}
      onConfirm={onConfirm}
      enableSubmit={enableSubmit()}
      isLoading={isLoading}
      errors={allValues.errors}
      userName={userName}
      verificationCode={allValues.verificationCode}
      handlePrimaryEmailVerification={handlePrimaryEmailVerification}
    />
  );
};
export const getValuesFromStore = (state) => ({
  person: meData(state),
  isLoading: meIsLoading(state),
  emailData: identityEmailData(state),
  emailToken: identityEmailToken(state),
});
EmailVerificationContainer.propTypes = {
  personData: PropTypes.object.isRequired,
  userName: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  completeProcess: PropTypes.func.isRequired,
  showEmailEditor: PropTypes.func.isRequired,
  handlePrimaryEmailVerification: PropTypes.func.isRequired,
};

export default EmailVerificationContainer;
