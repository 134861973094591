import { useEffect, useCallback } from 'react';
import amplitudeJS from 'amplitude-js';
import environment from "lib/environment";


const useAmplitude = (consoleObj = console) => {
  const currentEnvConfig = environment.get().amplitude;
  const amplitudeInstance = amplitudeJS.getInstance(currentEnvConfig.project);

  useEffect(() => {
    const stub = (reason) => {
        amplitudeInstance.logEvent = (eventName, payload) =>
            consoleObj.log(
                `[Amplitude Stubbed - logEvent - ${reason}] - Would have sent ${eventName}`,
                sanitize(payload),
            );
        amplitudeInstance.setUserProperties = (userData) =>
            consoleObj.log(`[Amplitude Stubbed - setUserProperties - ${reason}] - Would have sent userData`, userData);
    };
    
    if (currentEnvConfig.disabled) {
        stub('currentEnvConfig is disabled');
    }
}, [amplitudeInstance, consoleObj, currentEnvConfig.disabled, sanitize]); // Include dependencies

  const initialize = (userKey) => {
    amplitudeInstance.setServerUrl(currentEnvConfig.serverUrl);
    amplitudeInstance.init(currentEnvConfig.apiKey, null, {
      includeReferrer: true,
    });
    amplitudeInstance.setUserId(userKey);
    amplitudeInstance.setVersionName('0.0.0-development-SNAPSHOT');
  };

  const serialize = useCallback((value) => {
    if (Array.isArray(value)) {
      if (value.some((val) => Array.isArray(val) || typeof val === 'object')) {
        return value.map(serialize).reduce((acc, v, index) => {
          acc[index] = v;
          return acc;
        }, {});
      }
      return value.map(serialize);
    }
    if (typeof value === 'object') {
      return Object.keys(value).reduce((acc, key) => {
        acc[key] = serialize(value[key]);
        return acc;
      }, {});
    }
    return value;
  },[]);

  const sanitize = useCallback((payload) => {
    const cleanData = {};
    Object.keys(payload).forEach((key) => {
      const value = payload[key];
      if (value) {
        cleanData[key] = serialize(value);
      }
    });
    return cleanData;
  },[serialize]);

  const identify = (userData) => {
    amplitudeInstance.setUserProperties(userData);
  };

  const track = (eventName, payload) => {
    amplitudeInstance.logEvent(eventName, sanitize(payload));
  };

  return { initialize, identify, track, sanitize };
};

export default useAmplitude;