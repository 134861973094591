import {
  daoMeGet,
  daoMePatch,
  daoAvatarUrlsGet,
  daoMePatchWithAuthProp,
} from 'dao/me-dao';
import { daoForceSamlSsoGet, daoLoginOptions } from 'dao/loginservice-dao';
import resolveRoute from 'lib/resolve-route';
import appconfig from 'config/appconfig';
import { timeZoneOptions } from 'routes/personal-info/assets/timezone-options';
import { isFauxLocale } from 'routes/personal-info/assets/locale-options';

// ------------------------------------
// Constants
// ------------------------------------
export const ME_REQUEST_GET = 'ME_REQUEST_GET';
export const ME_RECEIVE_GET_SUCCESS = 'ME_RECEIVE_GET_SUCCESS';
export const ME_RECEIVE_GET_FAILURE = 'ME_RECEIVE_GET_FAILURE';

export const ME_REQUEST_PATCH = 'ME_REQUEST_PATCH';
export const ME_REQUEST_PATCH_SUCCESS = 'ME_REQUEST_PATCH_SUCCESS';
export const ME_REQUEST_PATCH_FAILURE = 'ME_REQUEST_PATCH_FAILURE';

export const ME_REQUEST_WITH_AUTH_PATCH = 'ME_REQUEST_WITH_AUTH_PATCH';
export const ME_REQUEST_WITH_AUTH_PATCH_SUCCESS = 'ME_REQUEST_WITH_AUTH_PATCH_SUCCESS';
export const ME_REQUEST_WITH_AUTH_PATCH_FAILURE = 'ME_REQUEST_WITH_AUTH_PATCH_FAILURE';

export const ME_AVATAR_URLS_REQUEST_GET = 'ME_AVATAR_URLS_REQUEST_GET';
export const ME_AVATAR_URLS_RECEIVE_GET_SUCCESS = 'ME_AVATAR_URLS_RECEIVE_GET_SUCCESS';
export const ME_AVATAR_URLS_RECEIVE_GET_FAILURE = 'ME_AVATAR_URLS_RECEIVE_GET_FAILURE';

export const ME_AVATAR_IMAGE_SET = 'ME_AVATAR_IMAGE_SET';

export const ME_FORCE_SAML_SSO_LOGIN_REQUEST_GET = 'ME_FORCE_SAML_SSO_LOGIN_REQUEST_GET';
export const ME_FORCE_SAML_SSO_LOGIN_RECEIVE_GET_SUCCESS = 'ME_FORCE_SAML_SSO_LOGIN_RECEIVE_GET_SUCCESS';
export const ME_FORCE_SAML_SSO_LOGIN_RECEIVE_GET_FAILURE = 'ME_FORCE_SAML_SSO_LOGIN_RECEIVE_GET_FAILURE';

export const LOGIN_OPTIONS_GET = 'LOGIN_OPTIONS_GET';
export const LOGIN_OPTIONS_GET_SUCCESS = 'LOGIN_OPTIONS_GET_SUCCESS';
export const LOGIN_OPTIONS_GET_FAILURE = 'LOGIN_OPTIONS_GET_FAILURE';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  // put your module data under this object
  data: {
    displayName: '',
    locale: 'en_US',
    timezone: 'America/Los_Angeles',
  },
  // generic or global properties go here

  // Contains avatar image source specified by the user during the session.
  // This source serves as a temporary storage to improve user experience, since uploaded images take time to become available.
  newAvatarImageSource: '',

  forceSamlSsoLogin: false,
  loginOptions: [],
  isLoading: false,
};

// ------------------------------------
// Selectors
// ------------------------------------
export const meIsLoading = (state) => state.me.isLoading;

export const meAllLoginOptions = (state) => state.me.loginOptions || [];

export const meData = (state) => state.me.data;

export const meNewAvatarImageSource = (state) => state.me.newAvatarImageSource;

export const meAvatarLocationUrls = (state) => state.me.avatarLocationUrls;

export const meForceSamlSsoLogin = (state) => state.me.forceSamlSsoLogin || false;

export const meLocale = (state) => {
  const data = meData(state);
  return data && data.locale;
};

export const meId = (state) => {
  const data = meData(state);
  return data && data.id;
};

export const meUserName = (state) => {
  const data = meData(state);
  return data && data.userName;
};

export const meDisplayName = (state) => {
  const data = meData(state);
  return data && data.displayName;
};

export const getGetGoSchema = (state) => {
  const data = meData(state);
  return data[appconfig.scimSchemas.getGo] || null;
};

export const meIsOrganizationMember = (state) => {
  const getGoSchema = getGetGoSchema(state);
  return getGoSchema && getGoSchema.organizationMember;
};

export const meEntitlements = (state) => {
  const data = meData(state);
  return data && data.entitlements;
};

export const meProducts = (state) => {
  const entitlements = meEntitlements(state) || [];
  return entitlements.filter(entitlement => (
    (entitlement !== 'acctadmin') &&
    (entitlement !== 'orgadmin') &&
    (entitlement !== 'hvoice')
  ));
};

export const meIsOrganizationAdmin = (state) => {
  const entitlements = meEntitlements(state);
  return (entitlements && (entitlements.indexOf('orgadmin') > -1)) || false;
};

export const meIsAccountAdmin = (state) => {
  const entitlements = meEntitlements(state);
  return (entitlements && (entitlements.indexOf('acctadmin') > -1)) || false;
};

export const meLoginOptions = (state) => {
  const getGoSchema = getGetGoSchema(state);
  return getGoSchema && getGoSchema.loginOptions;
};

export const mePasswordLoginEnabled = (state) => {
  const loginOptions = meLoginOptions(state);
  return loginOptions && loginOptions.password;
};

export const meSocialLoginEnabled = (state) => {
  const loginOptions = meLoginOptions(state);
  return loginOptions && loginOptions.social;
};

export const mePasskeyLoginEnabled = (state) => {
  const loginOptions = meLoginOptions(state);
  return loginOptions && loginOptions.passkey;
};

export const meIsEmailVerified = (state) => {
  const getGoSchema = getGetGoSchema(state);
  return getGoSchema && getGoSchema.emailVerified;
};

export const meIsEmailChangeAllowed = (state) => {
  const getGoSchema = getGetGoSchema(state);
  return getGoSchema && getGoSchema.emailChangeAllowed;
};

export const meSupportExperience = (state) => {
  const getGoSchema = getGetGoSchema(state);
  return getGoSchema && getGoSchema.supportExperience;
};

export const meEnhancedSecurity = (state) => {
  const getGoSchema = getGetGoSchema(state);
  return getGoSchema && (getGoSchema.enhancedSecurity || false);
};

export const meTfaEnrolled = (state) => {
  const getGoSchema = getGetGoSchema(state);
  return getGoSchema && (getGoSchema.tfaEnrolled || false);
};

export const meIsConflicted = (state) => {
  const getGoSchema = getGetGoSchema(state);
  return !!(getGoSchema && getGoSchema.conflicted);
};

export const meDomain = (state) => {
  const data = meData(state);
  const userName = data.userName || '';
  let domainName = '';
  if (userName.length && userName.indexOf('@') > 1) {
    domainName = userName.split('@')[1];
  }
  return domainName;
};

// ------------------------------------
// Actions
// ------------------------------------
export const meReceiveGetSuccess = (payload = {}) => ({
  type: ME_RECEIVE_GET_SUCCESS,
  payload,
});

export const meReceiveGetFailure = (payload = {}) => ({
  type: ME_RECEIVE_GET_FAILURE,
  payload,
});

export const meRequestGet = () => dispatch => {
  dispatch({
    type: ME_REQUEST_GET,
  });

  return daoMeGet()
    .then(
      response => {
        dispatch(meReceiveGetSuccess(response.data));
        if (response.data.id.length < 1) {
          history.push(resolveRoute('unauthorized'));
        }
      },
      ex => dispatch(meReceiveGetFailure(ex)),
    );
};

export const meRequestPatchSuccess = (payload = {}) => ({
  type: ME_REQUEST_PATCH_SUCCESS,
  payload,
});

export const meRequestPatchFailure = (payload = {}) => ({
  type: ME_REQUEST_PATCH_FAILURE,
  payload,
});

export const meRequestPatch = (payload = {}) => dispatch => {
  dispatch({
    type: ME_REQUEST_PATCH,
  });

  return daoMePatch(payload)
    .then(
      response => dispatch(meRequestPatchSuccess(response.data)),
      ex => {
        dispatch(meRequestPatchFailure(ex));
        return Promise.reject(ex);
      },
    );
};

export const meRequestPatchWithAuthSuccess = (payload = {}) => ({
  type: ME_REQUEST_WITH_AUTH_PATCH_SUCCESS,
  payload,
});

export const meRequestPatchWithAuthFailure = (payload = {}) => ({
  type: ME_REQUEST_WITH_AUTH_PATCH_FAILURE,
  payload,
});

export const meRequestPatchWithAuth = (authProp = '', payload = {}) => dispatch => {
  dispatch({
    type: ME_REQUEST_WITH_AUTH_PATCH,
  });

  return daoMePatchWithAuthProp(authProp, payload)
    .then(
      response => dispatch(meRequestPatchWithAuthSuccess(response.data)),
      ex => {
        dispatch(meRequestPatchWithAuthFailure(ex));
        return Promise.reject(ex);
      },
    );
};

export const meAvatarUrlsReceiveGetSuccess = (payload = {}) => ({
  type: ME_AVATAR_URLS_RECEIVE_GET_SUCCESS,
  payload,
});

export const meAvatarUrlsReceiveGetFailure = (payload = {}) => ({
  type: ME_AVATAR_URLS_RECEIVE_GET_FAILURE,
  payload,
});

export const meAvatarUrlsRequestGet = () => dispatch => {
  dispatch({
    type: ME_AVATAR_URLS_REQUEST_GET,
  });

  return daoAvatarUrlsGet()
    .then(
      response => dispatch(meAvatarUrlsReceiveGetSuccess(response.data)),
      ex => dispatch(meAvatarUrlsReceiveGetFailure(ex)),
    );
};

export const meAvatarImageSet = (payload = '') => ({
  type: ME_AVATAR_IMAGE_SET,
  payload,
});

export const meLoginOptionsGetSuccess = (payload = {}) => ({
  type: LOGIN_OPTIONS_GET_SUCCESS,
  payload,
});

export const meLoginOptionsGetFailure = (payload = {}) => ({
  type: LOGIN_OPTIONS_GET_FAILURE,
  payload,
});

export const meLoginOptionsGet = (email) => dispatch => {
  dispatch({
    type: LOGIN_OPTIONS_GET,
  });

  return daoLoginOptions(email)
    .then(
      response => dispatch(meLoginOptionsGetSuccess(response.data)),
      ex => dispatch(meLoginOptionsGetFailure(ex)),
    );
};

export const meForceSamlSsoLoginReceiveGetSuccess = (payload = {}) => ({
  type: ME_FORCE_SAML_SSO_LOGIN_RECEIVE_GET_SUCCESS,
  payload,
});

export const meForceSamlSsoLoginReceiveGetFailure = (payload = {}) => ({
  type: ME_FORCE_SAML_SSO_LOGIN_RECEIVE_GET_FAILURE,
  payload,
});

export const meForceSamlSsoLoginRequestGet = (email) => dispatch => {
  dispatch({
    type: ME_FORCE_SAML_SSO_LOGIN_REQUEST_GET,
  });

  return daoForceSamlSsoGet(email)
    .then(
      response => dispatch(meForceSamlSsoLoginReceiveGetSuccess(response.data)),
      ex => dispatch(meForceSamlSsoLoginReceiveGetFailure(ex)),
    );
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [ME_REQUEST_GET]: (state) => ({ ...state, isLoading: true }),

  [ME_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      let language = '';
      const payload = action.payload;
      const locale = (payload && payload.locale) || '';
      const foundLocale = locale && appconfig.supportedLocales.includes(locale);
      if (foundLocale) {
        payload.locale = locale;
      } else if (locale.length >= 2) { // some products use language designation only (e.g., "fr"), so match the closest available locale
        language = locale.substring(0, 2).toLowerCase();
        const substituteLocale = appconfig.supportedLocales.find(supportedLocale => supportedLocale.toLowerCase().indexOf(language) === 0);
        payload.locale = substituteLocale || 'en_US';
      } else {
        payload.locale = 'en_US';
      }
      payload.selectedLocale = payload.locale;
      if (locale.toLowerCase() === 'en_gb') {
        payload.selectedLocale = 'en_GB';
      } else if (language.length === 2 && isFauxLocale(language)) {
        payload.selectedLocale = language;
      }
      if (!payload.timezone) {
        payload.timezone = 'America/Los_Angeles';
      }
      return { ...state, data: payload, isLoading: false };
    }
    return { ...state, isLoading: false };
  },

  // @TODO (PMP-14) determine what to do when GET ME fails in UX (retry, some simple error message, redirect?)
  [ME_RECEIVE_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [ME_REQUEST_PATCH]: (state) => ({ ...state, isLoading: true }),

  [ME_REQUEST_PATCH_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      const payload = action.payload;
      if (payload.locale) {
        const userLanguage = payload.locale.substring(0, 2).toLowerCase();
        payload.selectedLocale = isFauxLocale(userLanguage) ? userLanguage : payload.locale;
      }
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
        isLoading: false,
      };
    }
    return { ...state, isLoading: false };
  },

  [ME_REQUEST_PATCH_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [ME_REQUEST_WITH_AUTH_PATCH]: (state) => ({ ...state, isLoading: true }),

  [ME_REQUEST_WITH_AUTH_PATCH_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      const payload = action.payload;
      if (payload.locale) {
        const userLanguage = payload.locale.substring(0, 2).toLowerCase();
        payload.selectedLocale = isFauxLocale(userLanguage) ? userLanguage : payload.locale;
      }
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
        isLoading: false,
      };
    }
    return { ...state, isLoading: false };
  },

  [ME_REQUEST_WITH_AUTH_PATCH_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [ME_AVATAR_URLS_REQUEST_GET]: (state) => ({ ...state, isLoading: true }),

  [ME_AVATAR_URLS_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        avatarLocationUrls: { ...action.payload },
        isLoading: false,
      };
    }
    return { ...state, isLoading: false };
  },

  [ME_AVATAR_URLS_RECEIVE_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [ME_AVATAR_IMAGE_SET]: (state, action) => {
    if (typeof action.payload === 'string' && action.payload.length) {
      return {
        ...state,
        newAvatarImageSource: action.payload,
        isLoading: false,
      };
    }
    return { ...state, isLoading: false };
  },

  [ME_FORCE_SAML_SSO_LOGIN_REQUEST_GET]: (state) => ({ ...state, isLoading: true }),

  [ME_FORCE_SAML_SSO_LOGIN_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        forceSamlSsoLogin: action.payload.enforceSaml || false,
        isLoading: false,
      };
    }
    return { ...state, isLoading: false };
  },

  [ME_FORCE_SAML_SSO_LOGIN_RECEIVE_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [LOGIN_OPTIONS_GET]: (state) => ({ ...state, isLoading: false }),
  [LOGIN_OPTIONS_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        loginOptions: action.payload || [],
        isLoading: false,
      };
    }
  },
  [LOGIN_OPTIONS_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const meReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default meReducer;
