import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@getgo/chameleon-web-react-wrapper';
import classes from './dialog.scss';
import { Typography } from '@getgo/chameleon-web-react-wrapper';
import { ArrowLeftOutlinedIcon, TrashOutlinedIcon } from '@getgo/chameleon-icons/react';

const DialogueView = ({
  onClosed,
  open,
  title,
  closable,
  actions,
  children
}) => {
  return (
    <div>
      <div className={classes["flexSideSmall"]}>
        <Dialog
          open={open}
          title={title}
          closable={closable}
          onClosed={onClosed}
          closeButtonLabel="close"
          actions={actions}
        >
          <div className={classes["dialogChildren"]}>
            {children}
          </div>
        </Dialog>
      </div>
      {open && <div className={classes["flexBottomLarge"]}>
        <div className={classes["modalContainer"]}>
          <div className={classes["modalHeader"]}>
            {closable &&
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div onClick={onClosed} className={classes["returnIcon"]}>
                <ArrowLeftOutlinedIcon size="20px" />
              </div>}
            <Typography variant="heading-small" color="#25282D" style={{ margin: "auto" }}>
              {title}
            </Typography>
          </div>
          <div className={classes["modalContent"]}>
            {children}
          </div>
          {actions && <div className={classes["modalActions"]}>
            {actions}
          </div>}
        </div>
      </div>}
    </div>
  );
};

DialogueView.propTypes = {
  onClosed: PropTypes.func,
  children: PropTypes.element,
  open: PropTypes.bool.isRequired,
  title: PropTypes.object.isRequired,
  closable: PropTypes.bool,
  actions: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ])
};

export default DialogueView;
