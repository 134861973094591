import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classes from './intl.scss';

const LabelValueVerticalIntl = ({data, intl}) => (
  <ul className={`list-inline no-margin ${classes['label-value-vertical']}`}>
    {data.icon &&
      <li>
        <i className={`togo-icon ${data.icon}`} />
      </li>}
    <li className="togo-font-light">
      <div className={`value ${classes['value']}`}>{data.value}</div>
      <div className="value-label">
        {(data.label.hasOwnProperty('defaultMessage')) ? intl.formatMessage(data.label) : data.label}
      </div>
    </li>
  </ul>
);

LabelValueVerticalIntl.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.string,
    value: PropTypes.any.isRequired,
    label: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired,
      }),
      PropTypes.node,
    ]),
  }),
  intl: PropTypes.object.isRequired,
};

export default injectIntl(LabelValueVerticalIntl);
