import React from "react";
import PropTypes from "prop-types";
import PasswordInput from "components/password-input-view";
import {
  FormattedMessage,
  defineMessages,
  useIntl,
} from "react-intl";
import st from "core/shared-translations";
import classes from "./signin-security.scss";
import BoxedContainer from "components/boxed-container-view";
import { EditOutlinedIcon, KeyOutlinedIcon } from "@getgo/chameleon-icons/react";
import { Button } from '@getgo/chameleon-web-react-wrapper';
import ResponsiveDialog from 'components/dialog-view.js';

const t = defineMessages({
  "password-editor.password": {
    id: "password-editor.password",
    defaultMessage: "Password",
    description: "Title string for the password editor section",
  },
  "password-editor.current-password": {
    id: "password-editor.current-password",
    defaultMessage: "Current password",
    description: "Field label for the current password field",
  },
  "password-editor.edit-password": {
    id: "password-editor.edit-password",
    defaultMessage: "Edit Password",
    description: "Title string for the password editor modal dialog",
  },
  "password-editor.new-password": {
    id: "password-editor.new-password",
    defaultMessage: "New password",
    description: "Field label for the new password field",
  },
  "password-editor.forgot-password": {
    id: "password-editor.forgot-password",
    defaultMessage: "Forgot your password?",
    description: "Link text for triggering the recover password process",
  },
  "password-editor.confirm-password": {
    id: "password-editor.confirm-password",
    defaultMessage: "Confirm new password",
    description: "Field label for the confirm new password field",
  },
  "password-editor.hide-password": {
    id: "password-editor.hide-password",
    defaultMessage: "Hide password",
    description: "Toggle aria-label for when the password is displayed as text",
  },
  "password-editor.show-password": {
    id: "password-editor.show-password",
    defaultMessage: "Show password",
    description:
      "Toggle aria-label for when the password is displayed as hidden characters",
  },
  "password-editor.mismatched-passwords": {
    id: "password-editor.mismatched-passwords",
    defaultMessage: "Enter a matching password.",
    description:
      "Content of the error message when the New and Confirm password fields value does not match",
  },
  "password-editor.unlink-last-social-message": {
    id: "password-editor.unlink-last-social-message",
    defaultMessage:
      "To stay signed in, you can connect through a different social sign-in, or create a new password to sign in directly.",
    description:
      "Instructions displayed when the last social account is being unlinked",
  },
  "password-editor.all-set": {
    id: "password-editor.all-set",
    defaultMessage: "All Set!",
    description: "Title string for the All Set editor section",
  },
  "password-editor.account-reset": {
    id: "password-editor.account-reset",
    defaultMessage: "You've disconnected your {socialAccount} account.",
    description: "Title string for the All Set editor section",
  },
  "password-editor.forward-login-instructions": {
    id: "password-editor.forward-login-instructions",
    defaultMessage:
      "You can now sign in using your new password and email address <b>{userName}</b>.",
    description: "Title string for the All Set editor section",
  },
  "password-editor.save-password-failed": {
    id: "password-editor.save-password-failed",
    defaultMessage: "Enter your current password correctly.",
    description:
      "Content of the error message when the password save has failed.",
  },
  "password-editor.save-password-failed-reused-password": {
    id: "password-editor.save-password-failed-reused-password",
    defaultMessage:
      "Enter a password that is not the same as your last 5 passwords to meet the security requirements of your account.",
    description:
      "Content of the error message when the password save has failed due to a reused password under password rotation policy",
  },
  "password-editor.validation-missing-current-password": {
    id: "password-editor.validation-missing-current-password",
    defaultMessage: "Password is a required field",
    description:
      "Content of the error message when the current password field is left empty",
  },
});

const maxLength = "32";
const PasswordEditor = ({
  personData,
  onEditLinkClick,
  modalIsOpen,
  closeModal,
  currentPassword,
  newPassword,
  confirmPassword,
  forgotPasswordOnClick,
  onViewAsTextClick,
  onViewAsTextByKeyboard,
  viewPasswordAsText,
  onChange,
  onBlur,
  onSave,
  onFocus,
  enableSubmit,
  passwordSet,
  passwordLoginEnabled,
  isLoading,
  errors,
  disconnectLastSocialAccount,
  socialLinksToDisplay,
  closeAllSetModal,
  disconnectedSocialAccountName,
  showAllSetTitle,
  passwordEditorDisplayClass,
  allSetClass,
  storeDisconnectedSocialConnection,
  isViewOnly = false,
  passwordStrengthScore,
  showWeakPasswordWarning = false
}) => {
  /* eslint-disable */
  const intl = useIntl();
  return (
    <div>
      <BoxedContainer
        title={<FormattedMessage {...t["password-editor.password"]} />}
        buttonIcon={<EditOutlinedIcon />}
        buttonText={<FormattedMessage {...st["shared.edit"]} />}
        onClick={onEditLinkClick}
        disabled={isViewOnly || !passwordLoginEnabled}
        boxIcon={<KeyOutlinedIcon color="#000000" />}
      >
        {passwordSet && passwordLoginEnabled ?
          ("**********") : (
            <FormattedMessage {...st["shared.none-set"]} />
          )}
      </BoxedContainer>
      <ResponsiveDialog
        closable={true}
        onClosed={closeModal}
        open={modalIsOpen}
        title={
          (!disconnectLastSocialAccount && !showAllSetTitle && (
            <FormattedMessage {...t["password-editor.edit-password"]} />
          )) ||
          (disconnectLastSocialAccount && !showAllSetTitle && (
            <FormattedMessage {...st["shared.almost-there"]} />
          )) ||
          (showAllSetTitle && (
            <FormattedMessage {...t["password-editor.all-set"]} />
          ))
        }
        actions={<React.Fragment>
          <Button
            onClick={onSave}
            disabled={!enableSubmit}
          >
            {isLoading ? (
              <FormattedMessage {...st["shared.saving"]} />
            ) : (
              <FormattedMessage {...st["shared.save"]} />
            )}
          </Button>
          <Button onClick={closeModal} variant="tertiary">
            <FormattedMessage {...st["shared.cancel"]} />
          </Button>
        </React.Fragment>}
      >
        {!disconnectLastSocialAccount && passwordSet && (
          <PasswordInput
            id="currentPassword"
            name="currentPassword"
            value={currentPassword}
            maxLength="255"
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            fullwidth={true}
            inputType={viewPasswordAsText.currentPassword ? "text" : "password"}
            onViewAsTextClick={onViewAsTextClick}
            onViewAsTextByKeyboard={onViewAsTextByKeyboard}
            type="password"
            label={intl.formatMessage(t["password-editor.current-password"])}
            error={
              errors.currentPassword &&
              intl.formatMessage(
                st[errors.currentPassword] || t[errors.currentPassword]
              )
            }
            showForgotPassword={{
              label: intl.formatMessage(
                t["password-editor.forgot-password"]
              ),
              href: forgotPasswordOnClick,
            }}
          />
        )}
        {disconnectLastSocialAccount && (
          <div
            id="orSeparator"
            className={classes["passwordEditOrSeparator"]}
          >
            <div className={classes["orDash"]} />
            <FormattedMessage {...st["shared.or"]} />
            <div className={classes["orDash"]} />
          </div>
        )}

        <PasswordInput
          id="newPassword"
          name="newPassword"
          label={intl.formatMessage(t["password-editor.new-password"])}
          value={newPassword}
          maxLength={maxLength}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          inputType={viewPasswordAsText.newPassword ? "text" : "password"}
          onViewAsTextClick={onViewAsTextClick}
          onViewAsTextByKeyboard={onViewAsTextByKeyboard}
          toggleAriaLabel={intl.formatMessage(
            t[
            viewPasswordAsText.newPassword
              ? "password-editor.hide-password"
              : "password-editor.show-password"
            ]
          )}
          passwordStrengthScore={passwordStrengthScore}
          error={
            errors.newPassword &&
            intl.formatMessage(
              st[errors.newPassword] || t[errors.newPassword]
            )
          }
          showWeakPasswordWarning={showWeakPasswordWarning}
        />

        <PasswordInput
          id="confirmPassword"
          name="confirmPassword"
          label={intl.formatMessage(t["password-editor.confirm-password"])}
          value={confirmPassword}
          maxLength={maxLength}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          inputType={
            viewPasswordAsText.confirmPassword ? "text" : "password"
          }
          toggleAriaLabel={intl.formatMessage(
            t[
            viewPasswordAsText.confirmPassword
              ? "password-editor.hide-password"
              : "password-editor.show-password"
            ]
          )}
          passwordStrengthScore={passwordStrengthScore}
          onViewAsTextClick={onViewAsTextClick}
          onViewAsTextByKeyboard={onViewAsTextByKeyboard}
          error={
            errors.confirmPassword &&
            intl.formatMessage(
              st[errors.confirmPassword] || t[errors.confirmPassword]
            )
          }
        />
      </ResponsiveDialog>
    </div>
  );
};

PasswordEditor.propTypes = {
  personData: PropTypes.object.isRequired,
  onEditLinkClick: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  passwordSet: PropTypes.bool.isRequired,
  passwordLoginEnabled: PropTypes.bool.isRequired,
  currentPassword: PropTypes.string,
  newPassword: PropTypes.string,
  confirmPassword: PropTypes.string,
  forgotPasswordOnClick: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  enableSubmit: PropTypes.bool.isRequired,
  onViewAsTextClick: PropTypes.func.isRequired,
  onViewAsTextByKeyboard: PropTypes.func.isRequired,
  viewPasswordAsText: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  disconnectLastSocialAccount: PropTypes.bool.isRequired,
  socialLinksToDisplay: PropTypes.object.isRequired,
  closeAllSetModal: PropTypes.func.isRequired,
  disconnectedSocialAccountName: PropTypes.string.isRequired,
  showAllSetTitle: PropTypes.bool.isRequired,
  passwordEditorDisplayClass: PropTypes.string.isRequired,
  allSetClass: PropTypes.string.isRequired,
  storeDisconnectedSocialConnection: PropTypes.func.isRequired,
  isViewOnly: PropTypes.bool,
  passwordStrengthScore: PropTypes.number.isRequired,
  showWeakPasswordWarning: PropTypes.bool.isRequired,
};

export default PasswordEditor;
