import { connect } from 'react-redux';
import { uiAlertAuthenticationExpiredToggle } from 'modules/ui-module';
import AlertAuthExpired from 'components/alert-auth-expired-view';

export const mapDispatchToProps = (dispatch) => ({
  handleAlertDismiss: () => dispatch(uiAlertAuthenticationExpiredToggle()),
});

export const mergeProps = (stateProps, dispatchProps) => ({
  handleAlertDismiss: dispatchProps.handleAlertDismiss,
  handleLogin: () => window.location.reload(),
});

// eslint-disable-next-line react-redux/connect-prefer-named-arguments
export default connect(undefined, mapDispatchToProps, mergeProps)(AlertAuthExpired);
