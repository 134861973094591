/* eslint-disable */
import React, { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import PasswordEditor from "../components/password-editor-view";
import {
  meData,
  getGetGoSchema,
  meRequestPatchWithAuth,
  mePasswordLoginEnabled,
  meIsLoading,
  meIsConflicted,
} from "modules/me-module";
import { uiShowToastrMessage } from "modules/ui-module";
import {
  socialDisconnectLastAccount,
  socialDisconnectedAccountName,
  setModeDisconnectLastSocialAccount,
  setDisconnectedSocialAccountName,
  socialProviderByUserIdDelete,
} from "modules/social-module";
import appconfig from "config/appconfig";
import {
  getSocialAccountName,
  getForgotPasswordUrl,
  stopEvent
} from "lib/utility-functions";
import Session from "lib/session";
import { setHandledErrorCodes } from "lib/error-message-handling";
import { Base64 } from "js-base64";
var zxcvbn = require("zxcvbn");
import { debounceMyFunction } from "lib/utility-functions";

export const PasswordEditorContainer = ({ socialSignInLinks }) => {
  let {
    personData,
    passwordSet,
    passwordLoginEnabled,
    isLoading,
    disconnectLastSocialAccount,
    disconnectedSocialAccountName,
    isViewOnly,
  } = useSelector(getValuesFromStore);
  let dispatch = useDispatch();
  let [allValues, setAllValues] = useState({
    personData: Object.assign({}, personData),
    errors: {},
    isDirty: false,
    modalIsOpen: false,
    newPassword: "",
    currentPassword: "",
    confirmPassword: "",
    viewPasswordAsText: {
      newPassword: false,
      currentPassword: false,
      confirmPassword: false,
    },
    enteredPasswordsMatch: false,
    socialLinksToDisplay: {},
    passwordEditorDisplayClass: "",
    allSetClass: "hidden",
    showAllSetTitle: false,
  });
  let [passwordStrengthScore, setPasswordStrengthScore] = useState(-1);
  let [userDataUpdate, setUserDataUpdate] = useState();
  let [showWeakPasswordWarning, setWeakPasswordWarning] = useState(false);

  //seperating this function because we want to apply debouncing to only password mismatch aspect
  const passwordMismatchError = (passwordMismatch, stateChanges) => {
    if (passwordMismatch) {
      stateChanges.errors["confirmPassword"] =
        "password-editor.mismatched-passwords";
    } else {
      delete stateChanges.errors["confirmPassword"];
    }
    setUserDataUpdate(stateChanges);
  };

  const actionWrapper = useCallback(
    debounceMyFunction(passwordMismatchError, 1000),
    []
  );

  const validateUserInput = async (field, value, onBlurCheck) => {
    let stateChanges = {};
    let blnContinue = true;
    let passwordMismatch = false;

    stateChanges.errors = { ...allValues.errors };
    if (stateChanges.errors[field]) {
      delete stateChanges.errors[field];
    }

    if (onBlurCheck) {
      if (field === "currentPassword" && !value.length) {
        stateChanges.errors[field] = "password-editor.validation-missing-current-password";
        blnContinue = false;
      } else if (field === "currentPassword") {
        if (allValues.newPassword !== allValues.confirmPassword) {
          passwordMismatch = true;
        }
      } else if (field === "newPassword") {
        if (value !== allValues.confirmPassword && allValues.confirmPassword.length) {
          passwordMismatch = true;
        }
      }
    }
    if (onBlurCheck && field === "newPassword") {
      let newPasswordStrength = zxcvbn(value).score;
      if (newPasswordStrength < 3 && !!value.length) {
        setWeakPasswordWarning(true);
      }
      else {
        setWeakPasswordWarning(false);
      }
    }

    if (blnContinue && field === "newPassword") {
      if (!value.length) {
        setPasswordStrengthScore(-1);
      } else {
        let passwordStrength = zxcvbn(value).score;
        setPasswordStrengthScore(passwordStrength);
      }
    }

    if (blnContinue && field === "confirmPassword") {
      passwordMismatch = false;
      if (value !== allValues.newPassword) {
        passwordMismatch = true;
      }
    }

    if (blnContinue && passwordMismatch) {
      stateChanges.errors["confirmPassword"] = "password-editor.mismatched-passwords";
    } else {
      delete stateChanges.errors["confirmPassword"];
    }

    setAllValues((prevState) => ({
      ...prevState,
      ...stateChanges,
    }));

    return blnContinue;
  };

  useEffect(() => {
    setAllValues((prevState) => ({
      ...prevState,
      ...userDataUpdate,
    }));
  }, [userDataUpdate]);

  const openModal = (e) => {
    stopEvent(e);
    setAllValues((prevState) => ({
      ...prevState,
      modalIsOpen: true,
    }));
  };

  const clearSocialAccountSettings = () => {
    dispatch(setModeDisconnectLastSocialAccount(""));
    dispatch(setDisconnectedSocialAccountName(""));
  };

  const clearSocialAccountCookieAndCloseModal = (e) => {
    stopEvent(e);
    Session.removeItem(appconfig.storage.disconnectSocialAccount);
    closeModal();
  };

  const closeModal = (e) => {
    if (disconnectedSocialAccountName.length && allValues.allSetClass !== "") {
      closeAllSetModal(e);
    }
    stopEvent(e);
    resetPasswordEntryFields();
    toggleAllSetContent(false);
    clearSocialAccountSettings();
    setPasswordStrengthScore(-1);
    setAllValues((prevState) => ({
      ...prevState,
      modalIsOpen: false,
    }));
  };

  const closeAllSetModal = (e) => {
    stopEvent(e);
    clearSocialAccountSettings();
    toggleAllSetContent(false);
    //closeModal();
  };

  const toggleAllSetContent = (showAllSet) => {
    const passwordEditorDisplayClass = showAllSet ? "hidden" : "";
    const allSetClass = showAllSet ? "" : "hidden";
    const showAllSetTitle =
      showAllSet && disconnectedSocialAccountName.length > 0;
    setAllValues((prevState) => ({
      ...prevState,
      passwordEditorDisplayClass,
      allSetClass,
      showAllSetTitle,
    }));
  };
  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
      const socialAccount = disconnectLastSocialAccount;
      if (socialAccount.length) {
        const linksToDisplay = {};
        if (socialAccount !== appconfig.socialAccounts.LASTPASS) {
          linksToDisplay[appconfig.socialAccounts.LASTPASS] =
            socialSignInLinks[appconfig.socialAccountLinks.LASTPASS];
        }
        if (socialAccount !== appconfig.socialAccounts.GOOGLE) {
          linksToDisplay[appconfig.socialAccounts.GOOGLE] =
            socialSignInLinks[appconfig.socialAccountLinks.GOOGLE];
        }
        if (socialAccount !== appconfig.socialAccounts.FACEBOOK) {
          linksToDisplay[appconfig.socialAccounts.FACEBOOK] =
            socialSignInLinks[appconfig.socialAccountLinks.FACEBOOK];
        }
        if (socialAccount !== appconfig.socialAccounts.LINKEDIN) {
          linksToDisplay[appconfig.socialAccounts.LINKEDIN] =
            socialSignInLinks[appconfig.socialAccountLinks.LINKEDIN];
        }
        if (socialAccount !== appconfig.socialAccounts.MICROSOFT) {
          linksToDisplay[appconfig.socialAccounts.MICROSOFT] =
            socialSignInLinks[appconfig.socialAccountLinks.MICROSOFT];
        }
        if (socialAccount !== appconfig.socialAccounts.APPLE) {
          linksToDisplay[appconfig.socialAccounts.APPLE] =
            socialSignInLinks[appconfig.socialAccountLinks.APPLE];
        }

        setAllValues((prevState) => ({
          ...prevState,
          socialLinksToDisplay: Object.assign({}, linksToDisplay),
        }));
        openModal();
      }
      mounted.current = true;
    } else {
      const linksToDisplay = {};
      // Only update list when the modal is not open yet.
      //   Otherwise, a previous Toastr message can update the state which will in effect the removal of the links from view unexpectedly.
      // disconnectLastSocialAccount !== prevDisconnectLastSocialAccount &&
      if (!allValues.modalIsOpen) {
        if (disconnectLastSocialAccount.length) {
          if (
            disconnectLastSocialAccount !== appconfig.socialAccounts.LASTPASS
          ) {
            linksToDisplay[appconfig.socialAccounts.LASTPASS] =
              socialSignInLinks[appconfig.socialAccountLinks.LASTPASS];
          }
          if (disconnectLastSocialAccount !== appconfig.socialAccounts.GOOGLE) {
            linksToDisplay[appconfig.socialAccounts.GOOGLE] =
              socialSignInLinks[appconfig.socialAccountLinks.GOOGLE];
          }
          if (
            disconnectLastSocialAccount !== appconfig.socialAccounts.FACEBOOK
          ) {
            linksToDisplay[appconfig.socialAccounts.FACEBOOK] =
              socialSignInLinks[appconfig.socialAccountLinks.FACEBOOK];
          }
          if (
            disconnectLastSocialAccount !== appconfig.socialAccounts.LINKEDIN
          ) {
            linksToDisplay[appconfig.socialAccounts.LINKEDIN] =
              socialSignInLinks[appconfig.socialAccountLinks.LINKEDIN];
          }
          if (
            disconnectLastSocialAccount !== appconfig.socialAccounts.MICROSOFT
          ) {
            linksToDisplay[appconfig.socialAccounts.MICROSOFT] =
              socialSignInLinks[appconfig.socialAccountLinks.MICROSOFT];
          }
          if (disconnectLastSocialAccount !== appconfig.socialAccounts.APPLE) {
            linksToDisplay[appconfig.socialAccounts.APPLE] =
              socialSignInLinks[appconfig.socialAccountLinks.APPLE];
          }
          openModal();
        }

        if (
          linksToDisplay[appconfig.socialAccounts.GOOGLE] ||
          linksToDisplay[appconfig.socialAccounts.FACEBOOK] ||
          linksToDisplay[appconfig.socialAccounts.LINKEDIN] ||
          linksToDisplay[appconfig.socialAccounts.MICROSOFT] ||
          linksToDisplay[appconfig.socialAccounts.LASTPASS] ||
          linksToDisplay[appconfig.socialAccounts.APPLE]
        ) {
          setAllValues((prevState) => ({
            ...prevState,
            socialLinksToDisplay: Object.assign({}, linksToDisplay),
          }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValues.disconnectLastSocialAccount]);

  const resetPasswordEntryFields = () => {
    setAllValues({
      personData: Object.assign({}, personData),
      errors: {},
      isDirty: false,
      modalIsOpen: false,
      newPassword: "",
      currentPassword: "",
      confirmPassword: "",
      viewPasswordAsText: {
        newPassword: false,
        currentPassword: false,
        confirmPassword: false,
      },
      enteredPasswordsMatch: false,
      socialLinksToDisplay: {},
      passwordEditorDisplayClass: "",
      allSetClass: "hidden",
      showAllSetTitle: false,
    });
  };

  const onBlur = (e) => {
    const field = (e.target.name || e.target.id).split("-")[0];
    const value = e.target.value;
    togglePasswordTextViews();
    return validateUserInput(field, value, true);
  };

  const onChange = (e) => {
    const data = { ...allValues };
    const field = (e.target.name || e.target.id).split("-")[0];
    const value = e.target.value;
    let passwordMismatch = false;

    data.errors = { ...allValues.errors };
    if (data.errors[field]) {
      delete data.errors[field];
    }
    if (
      field === "currentPassword" && // reset currentPassword error state onchange if it is a result of a server-side rejection (not a validation error);
      data.errors.currentPassword &&
      data.errors.currentPassword === "password-editor.save-password-failed"
    ) {
      delete data.errors.currentPassword;
    }
    if (field === "currentPassword") {
      if (allValues.newPassword !== allValues.confirmPassword) {
        passwordMismatch = true;
      }
    }
    if (field === "newPassword" && allValues.confirmPassword) {
      if (value !== allValues.confirmPassword) {
        passwordMismatch = true;
      }
    }
    if (field === "confirmPassword") {
      if (value !== allValues.newPassword) {
        passwordMismatch = true;
      }
    }

    //debouce function for password mismatch check
    actionWrapper(passwordMismatch, data);

    data.isDirty = true;
    if (validateUserInput(field, value, false)) {
      data[field] = value;
    }

    setAllValues(data);

    return true;
  };

  const onFocus = (e) => {
    const field = (e.target.name || e.target.id).split("-")[0];
    togglePasswordTextViews(field, true);
    return true;
  };

  const displayToastrMessage = (toastrType, toastrMessageId) => {
    dispatch(uiShowToastrMessage(toastrType, toastrMessageId));
  };

  const storeSocialAccountName = () => {
    setDisconnectedSocialAccountName(
      getSocialAccountName(disconnectLastSocialAccount)
    );
  };

  const onSave = () => {
    const socialAccountChange = disconnectLastSocialAccount.length;
    if (socialAccountChange) {
      storeSocialAccountName();
    }
    if (
      (!passwordSet &&
        !allValues.currentPassword.length &&
        allValues.newPassword.length) ||
      (passwordSet &&
        allValues.currentPassword.length &&
        allValues.newPassword.length) ||
      (socialAccountChange && allValues.newPassword.length)
    ) {
      setHandledErrorCodes(["invalid.credentials", "password.reuse"]);

      return dispatch(
        meRequestPatchWithAuth(
          `B64Password ${Base64.encode(allValues.currentPassword)}`,
          { password: allValues.newPassword || "" }
        )
      )
        .then(
          () => {
            displayToastrMessage(
              "success",
              socialAccountChange
                ? "password-editor.password-save-successful"
                : "password-editor.password-change-successful"
            );
            return socialAccountChange
              ? dispatch(
                socialProviderByUserIdDelete(
                  personData.id,
                  disconnectLastSocialAccount
                )
              )
              : undefined;
          },
          (ex) => {
            let stateChanges = {
              ...allValues,
            };
            if (
              ex &&
              ex.data.error &&
              ex.data.error.length &&
              ex.data.error.find((error) => error.code === "password.reuse")
            ) {
              stateChanges.errors["newPassword"] =
                "password-editor.save-password-failed-reused-password";
              setAllValues(stateChanges);
            } else {
              stateChanges.errors["currentPassword"] =
                "password-editor.save-password-failed";
              setAllValues(stateChanges);
            }
          }
        )
        .then(() => {
          if (Object.keys(allValues.errors).length === 0) {
            if (socialAccountChange) {
              toggleAllSetContent(true);
            } else {
              closeModal();
            }
          }
        });
    }
    return true;
  };

  const passwordEditEnabled = () => passwordLoginEnabled;

  const togglePasswordTextViews = (setTextViewOn, onFocusEvent) => {
    const data = { ...allValues };
    const onFocus = onFocusEvent || false;
    const switchFieldMode = typeof setTextViewOn === "string";
    let viewMode = false;
    if (switchFieldMode) {
      viewMode = data.viewPasswordAsText[setTextViewOn];
    }
    data.viewPasswordAsText.newPassword = false;
    data.viewPasswordAsText.currentPassword = false;
    data.viewPasswordAsText.confirmPassword = false;
    if (switchFieldMode) {
      data.viewPasswordAsText[setTextViewOn] = onFocus ? viewMode : !viewMode;
    }

    setAllValues(data);
  };

  const onViewAsTextClick = (event) => {
    const triggerElement = event.target;
    let fieldId = triggerElement.id;
    if (!fieldId) {
      // if the icon 'i' element was clicked then we need to get it's parent's (span tag) id.
      fieldId = triggerElement.parentNode.id;
    }
    togglePasswordTextViews(fieldId.split("_")[0], false);
    return true;
  };

  const onViewAsTextByKeyboard = (event) => {
    if (event.which === 13) {
      return onViewAsTextClick(event);
    }
    return false;
  };

  const enableSubmit = () => {
    return (
      Object.keys(allValues.errors).length === 0 &&
      allValues.isDirty &&
      !isLoading &&
      (allValues.currentPassword !== "" ||
        !passwordSet ||
        disconnectLastSocialAccount.length > 0) &&
      allValues.newPassword !== "" &&
      allValues.currentPassword !== allValues.newPassword &&
      allValues.newPassword === allValues.confirmPassword &&
      passwordStrengthScore > 2
    );
  };

  // currying, because function calls with params assigned to onClick events are called immediately
  // (https://www.sitepoint.com/currying-in-functional-javascript/)
  const storeDisconnectedSocialConnection = () => () => {
    Session.setItem(
      appconfig.storage.disconnectSocialAccount,
      disconnectLastSocialAccount
    );
    return true;
  };

  return (
    <PasswordEditor
      personData={allValues.personData}
      onEditLinkClick={openModal}
      modalIsOpen={allValues.modalIsOpen}
      closeModal={clearSocialAccountCookieAndCloseModal}
      passwordSet={passwordSet}
      passwordLoginEnabled={passwordEditEnabled()}
      currentPassword={allValues.currentPassword}
      newPassword={allValues.newPassword}
      confirmPassword={allValues.confirmPassword}
      forgotPasswordOnClick={getForgotPasswordUrl()}
      onViewAsTextClick={onViewAsTextClick}
      onViewAsTextByKeyboard={onViewAsTextByKeyboard}
      viewPasswordAsText={allValues.viewPasswordAsText}
      onChange={onChange}
      onBlur={onBlur}
      onSave={onSave}
      onFocus={onFocus}
      enableSubmit={enableSubmit()}
      isLoading={isLoading}
      errors={allValues.errors}
      disconnectLastSocialAccount={disconnectLastSocialAccount.length > 0}
      disconnectedSocialAccountName={disconnectedSocialAccountName}
      socialLinksToDisplay={allValues.socialLinksToDisplay}
      closeAllSetModal={closeAllSetModal}
      passwordEditorDisplayClass={allValues.passwordEditorDisplayClass}
      allSetClass={allValues.allSetClass}
      showAllSetTitle={allValues.showAllSetTitle}
      storeDisconnectedSocialConnection={storeDisconnectedSocialConnection}
      isViewOnly={isViewOnly}
      passwordStrengthScore={passwordStrengthScore}
      showWeakPasswordWarning={showWeakPasswordWarning}
    />
  );
};
export const getValuesFromStore = (state) => {
  let passwordSet = false;
  const getGoSchema = getGetGoSchema(state);
  if (getGoSchema && getGoSchema.passwordSet) {
    passwordSet = getGoSchema.passwordSet;
  }
  return {
    personData: meData(state),
    passwordSet,
    passwordLoginEnabled: mePasswordLoginEnabled(state),
    isLoading: meIsLoading(state),
    disconnectLastSocialAccount: socialDisconnectLastAccount(state),
    disconnectedSocialAccountName: socialDisconnectedAccountName(state),
    isViewOnly: meIsConflicted(state),
  };
};
PasswordEditorContainer.propTypes = {
  socialSignInLinks: PropTypes.object.isRequired,
};

export default PasswordEditorContainer;
