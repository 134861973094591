import api from 'dao/providers/axios';
import appconfig from 'config/appconfig';

const identityVerificationEmail = appconfig.endpoints.identityVerificationEmail;

export const daoEmailVerificationRequestPost = (locale, payload) => api.post(`${identityVerificationEmail}?link=false&locale=${locale}`, payload);

export const daoEmailVerificationCompletionPost = (emailToken, payload) => api.post(`${identityVerificationEmail}/${emailToken}`, payload);

export const daoEmailVerificationStatusGet = (emailToken) => api.get(`${identityVerificationEmail}/${emailToken}`);
