import { RouteNotFoundException } from 'lib/exceptions';
import appconfig from 'config/appconfig';

/**
 * @typedef {object} RouteResolverOptions
 *
 * Configures how a given route should resolve.
 *
 * @property {string[]} [routeParams=[]]: Parameters used to generate the resolved URL (order matters). These are applied, in order, as parameters
 *                                        to the matched route function from appconfig.
 * @property {string} [search='']: If set, the query string to append to the route.
 * @property {string} [hash='']: If set, the hash string to append to the route.
 */

/**
 * Creates a fully qualified route path string using routes defined in config/appconfig.js
 *
 * @param {string} routeName The name of the route you want to use (defined in config/appconfig.js)
 * @param {RouteResolverOptions} [options={}] configure what the specified route resolves to
 * @throws {RouteNotFoundException} Will throw if routeName does not match any route defined in appconfig
 * @returns {string}
 */
export const resolveRoute = (routeName, options = {}) => {
  const { routeParams = [], search = '', hash = '' } = options;
  const cleanRouteParams = (!Array.isArray(routeParams)) ? [] : routeParams;
  const cleanSearch = (search.length > 0 && search[0] !== '?') ? `?${search}` : search;
  const cleanHash = (hash.length > 0 && hash[0] !== '#') ? `#${hash}` : hash;

  if (appconfig.routes.hasOwnProperty(routeName)) {
    return `${appconfig.routes[routeName](...cleanRouteParams)}${cleanSearch}${cleanHash}`;
  } else if (appconfig.routes.noKey.hasOwnProperty(routeName)) {
    return `${appconfig.routes.noKey[routeName](...cleanRouteParams)}${cleanSearch}${cleanHash}`;
  }
  throw new RouteNotFoundException(routeName);
};

export default resolveRoute;
