/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import PasskeyVerification from "../components/passkey-verification-view";
import {
  identityEmailToken,
  emailVerificationCompletionPost,
  emailVerificationStatusGet
} from "modules/identity-module";
import { meLocale, meData } from "modules/me-module";
import { stopEvent } from "lib/utility-functions";
import { uiShowToastrMessage } from "modules/ui-module";
import { hasIllegalCharacters, numbersOnly } from "core/input-validations";
import {
  setHandledErrorCodes,
  removeHandledErrorCodes,
} from "lib/error-message-handling";

const INVALID_TOKEN_CODE = "invalid.token.code";
const EXPIRED_TOKEN = "token.expired";
const VERIFIED = "VERIFIED";

export const PasskeyVerificationContainer = ({
  completeProcess,
  sendVerificationEmail,
  closeModal,
  credentialIdForDeletion
}) => {
  const { meLocale, emailToken, meEmail, } = useSelector(getValuesFromStore);
  const [isLoading, setIsLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('')
  let dispatch = useDispatch();

  const displayToastrMessage = (toastrType, toastrMessageId, toastrParams = null, toastrMessageDescription = "") => {
    dispatch(uiShowToastrMessage(toastrType, toastrMessageId, toastrParams, toastrMessageDescription));
  };

  const getErrorCodesArray = () => {
    return ["user.username.conflict", INVALID_TOKEN_CODE, EXPIRED_TOKEN];
  };

  const validateUserInput = (value) => {
    let errorText = "";
    if (error.length) {
      setError('');
    }
    if (value.length > 6) {
      return false;
    }
    if (value.length <= 6) {
      if (hasIllegalCharacters(value)) {
        errorText = "shared.illegal-character-entry";
      }
      if (!errorText.length && value.length && !numbersOnly(value)) {
        errorText = "shared.email-verification.number-entry-validation-failure";
      }
    }
    if (errorText.length) {
      setError(errorText);
    }
    return true;
  }

  const onConfirm = (e) => {
    setIsLoading(true)
    stopEvent(e);
    setHandledErrorCodes(getErrorCodesArray());
    return dispatch(
      emailVerificationCompletionPost(emailToken, {
        code: verificationCode,
      })
    ).then(
      () => {
        dispatch(emailVerificationStatusGet(emailToken)).then(
          (response) => {
            if (response.payload && response.payload.status === VERIFIED) {
              completeProcess(credentialIdForDeletion);
            } else {
              removeHandledErrorCodes(getErrorCodesArray());
            }
            setIsLoading(false)
          },
          () => {
            removeHandledErrorCodes(getErrorCodesArray());
            displayToastrMessage(
              "error",
              "email-verification.email-confirmation-failed"
            );
            setIsLoading(false)
          }
        );
      },
      (ex) => {
        removeHandledErrorCodes(getErrorCodesArray());
        setIsLoading(false);
        if (ex && ex.status && ex.status === 422) {
          const errCode = ex.data && ex.data.code ? ex.data.code : undefined;
          let verificationError = "";
          if (errCode === INVALID_TOKEN_CODE) {
            verificationError = "shared.email-verification.entry-validation-failure";

            setError(verificationError)
            return true;
          } else if (errCode === EXPIRED_TOKEN) {
            verificationError = "shared.email-verification.token-timeout-failure";
            setError(verificationError)
            return true;
          }
        }
        displayToastrMessage(
          "error",
          "email-verification.email-confirmation-failed"
        );
        setIsLoading(false)
      }
    );
  }

  const onChange = (e) => {
    // const field = (e.target.name || e.target.id).split("-")[0];
    const value = e.target.value;
    if (validateUserInput(value)) {
      setVerificationCode(value);
      return true;
    }
  }

  const onBlur = (e) => {
    const value = e.target.value;
    return validateUserInput(value);
  };

  const enableSubmit = () => {
    return (
      error.length === 0 &&
      verificationCode.length === 6 &&
      !isLoading &&
      meEmail.length > 0
    );
  };
  return (
    <PasskeyVerification
      onBlur={onBlur}
      onChange={onChange}
      onConfirm={onConfirm}
      enableSubmit={enableSubmit()}
      isLoading={isLoading}
      error={error}
      userName={meEmail}
      verificationCode={verificationCode}
      sendVerificationEmail={sendVerificationEmail}
      closeModal={closeModal}
    />
  );
};

export const getValuesFromStore = (state) => {
  const mePersonData = meData(state);
  const emails = mePersonData.emails || [];
  const primaryEmail = emails.find((email) => email.type === "primary") || "";
  return {
    meLocale: meLocale(state),
    meEmail: primaryEmail.value,
    emailToken: identityEmailToken(state),
  }
};

export default PasskeyVerificationContainer;