import React from 'react';
import PropTypes from 'prop-types';

export const VCenter = ({children, ellipsis}) =>
  <div className={ellipsis ? 'vcenter-container-ellipsis' : 'vcenter-container'}>
    <div className="vcenter-content">
      {children}
    </div>
  </div>;

VCenter.propTypes = {
  children: PropTypes.node,
  ellipsis: PropTypes.bool,
};

export default VCenter;
