import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import history from "lib/history";
import Session from "lib/session";
import IntlContainer from "./intl-container";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from '@getgo/chameleon-web-react-wrapper';
import '@getgo/chameleon-web/components/registerAll';
import useAmplitude from 'lib/amplitude-tracking';

const AppContainer = ({ store, locale, authorized, accountKey }) => {
  const { initialize } = useAmplitude();
  
  /**
   * If there is a destination in storage then clear it out and route to it.
   * Does not use resolve-route because we are just handling user intent here
   */
  const routeToOriginalTarget = () => {
    if (authorized) {
      initialize(accountKey);
    } 
    const target = Session.getOriginalTarget();
    if (target) {
      Session.removeOriginalTarget();
      history.push(target);
    }
  };
  useEffect(() => {
    routeToOriginalTarget(store);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider>
      <Provider store={store}>
        <div style={{ height: "100%" }}>
          <IntlContainer meLocale={locale} />
        </div>
      </Provider>
    </ThemeProvider>
  );
};
AppContainer.propTypes = {
  store: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  authorized: PropTypes.string.isRequired,
  accountKey: PropTypes.string.isRequired
};

export default AppContainer;
