import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SignInSecurity from "../components/signin-security-view";
import {
  socialAccountsData,
  socialAccountsByUserIdGet,
  socialSignInLinks,
  socialSignInLinksGet,
} from "modules/social-module";
import {
  meId,
  meData,
  meIsOrganizationMember,
  meIsOrganizationAdmin,
  meSocialLoginEnabled,
  meForceSamlSsoLoginRequestGet,
  meForceSamlSsoLogin,
  mePasskeyLoginEnabled,
} from "modules/me-module";
import {
  organizationKey,
  orgServiceDomainsRequestGet,
} from "modules/organization-module";
import environment from "lib/environment";
import appconfig from "config/appconfig";
import Session from "lib/session";
import { getQueryStringParams } from "lib/utility-functions";

const oauthClientId = environment.get().oauth.client_id;

export const SignInSecurityContainer = () => {
  let {
    userId,
    personData,
    socialLoginEnabled,
    socialAccounts,
    enablePasskeyForUser,
    orgKey,
    isOrganizationMember,
    isOrganizationAdmin,
    socialSignInLinksList,
    forceSamlSsoLogin,
  } = useSelector(getValuesFromStore);
  const dispatch = useDispatch();

  let [openDevicesDialogOnLoad, setOpenDevicesDialogOnLoad] = useState(false);
  useEffect(() => {
    if (isOrganizationMember) {
      dispatch(orgServiceDomainsRequestGet(orgKey));
    }
    dispatch(socialAccountsByUserIdGet(userId));
    dispatch(meForceSamlSsoLoginRequestGet(personData.userName));

    if (!Session.getItem("devicesDialog")) {
      const queryValues = getQueryStringParams(location.search);
      if (queryValues.devices) {
        Session.setItem("devicesDialog", "true");
        setOpenDevicesDialogOnLoad(true);
      }
    }

    const params = {
      redirectUrl: encodeURIComponent(location.href.split("#")[0]),
      clientId: oauthClientId,
    };
    dispatch(socialSignInLinksGet(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enterpriseSignInEnabled = () => {
    let showEnterpriseSignIn = false;
    const emailParts = personData.userName.split("@");
    if (
      appconfig.blackListedDomains.indexOf(
        emailParts[1].split(".")[0].toLowerCase()
      ) === -1
    ) {
      showEnterpriseSignIn = true;
    }
    if (isOrganizationMember && !isOrganizationAdmin) {
      showEnterpriseSignIn = false;
    }
    return showEnterpriseSignIn;
  };

  return (
    <SignInSecurity
      isOrganizationMember={isOrganizationMember}
      isOrganizationAdmin={isOrganizationAdmin}
      socialLoginEnabled={socialLoginEnabled && !forceSamlSsoLogin}
      socialAccounts={socialAccounts}
      socialSignInLinks={socialSignInLinksList}
      enterpriseSignInEnabled={enterpriseSignInEnabled()}
      forceSamlSsoLogin={forceSamlSsoLogin}
      openDevicesDialogOnLoad={openDevicesDialogOnLoad}
      enablePasskeyForUser={enablePasskeyForUser}
    />
  );
};
export const getValuesFromStore = (state) => {
  const mePersonData = meData(state);
  const emails = mePersonData.emails || [];
  const primaryEmail = emails.find((email) => email.type === "primary") || "";
  mePersonData.email = primaryEmail.value;
  return {
    userId: meId(state),
    enablePasskeyForUser: mePasskeyLoginEnabled(state),
    personData: mePersonData,
    socialLoginEnabled: meSocialLoginEnabled(state),
    socialAccounts: socialAccountsData(state),
    orgKey: organizationKey(state),
    isOrganizationMember: meIsOrganizationMember(state),
    isOrganizationAdmin: meIsOrganizationAdmin(state),
    socialSignInLinksList: socialSignInLinks(state) || {},
    forceSamlSsoLogin: meForceSamlSsoLogin(state),
  };
};

export default SignInSecurityContainer;
