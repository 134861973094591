import environment from 'lib/environment';
import * as Castle from '@castleio/castle-js';

let isCastleConfigured = false;

/**
 * Convenience interface for working with the Castle.IO javascript API (for Devices Review).
 * See https://docs.castle.io/docs, https://docs.castle.io/docs/quickstart, and https://docs.castle.io/docs/sdk-browser
 */
export class CastleIOWrapper {
  /**
   * In Castle.IO: Set the Publishable Key value, identify the logged-in user, and register the current page (view).
   *
   * @param {string} userId: the user's ID from SCIM API.
   * @param {string} userName: the user's email address from SCIM API.
   * @param {string} pageTitle: the english-only string of the current view/tab (e.g., Sign In & Security, Personal Info)
   * @param {object} [win = window]: the window object or a spy/mock for testing.
   * @param {Environment} [env = environment]: the environment from which to get configuration
   */
  identifyUserAndTrackPageInCastle(userId, userName, pageTitle, win = window, env = environment) {

    if (!isCastleConfigured) {
      Castle.configure({
        pk: env.get().keys.castleIoPublishableKey,
        avoidCookies: true,
      });
      isCastleConfigured = true;
    }

    const userProps = {
      id: userId
    };

    if (!!userName) {
      userProps.email = userName;
    };

    Castle.page(
      {
        user: userProps,
        page: {
          url: win.location.href.substring(win.location.href.lastIndexOf('/')),
          title: pageTitle,
        }
      }
    );
  }
}

export default __TEST__ ? {} : new CastleIOWrapper(); // eslint-disable-line
