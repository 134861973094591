import {
  daoOrganizationMeGet,
  // daoOrganizationByOrganizationKeyGet,
  daoOrganizationDomainsGet, /* ,
  organizationAdminsEndpoint */
} from 'dao/orgservice-dao';

// ------------------------------------
// Constants
// ------------------------------------
export const ORGSERVICE_ME_REQUEST_GET = 'ORGSERVICE_ME_REQUEST_GET';
export const ORGSERVICE_ME_RECEIVE_GET_SUCCESS = 'ORGSERVICE_ME_RECEIVE_GET_SUCCESS';
export const ORGSERVICE_ME_RECEIVE_GET_FAILURE = 'ORGSERVICE_ME_RECEIVE_GET_FAILURE';

// export const ORGSERVICE_ADMINISTRATORS_REQUEST_GET = 'ORGSERVICE_ADMINISTRATORS_REQUEST_GET';
// export const ORGSERVICE_ADMINISTRATORS_RECEIVE_GET_SUCCESS = 'ORGSERVICE_ADMINISTRATORS_RECEIVE_GET_SUCCESS';
// export const ORGSERVICE_ADMINISTRATORS_RECEIVE_GET_FAILURE = 'ORGSERVICE_ADMINISTRATORS_RECEIVE_GET_FAILURE';

export const ORGSERVICE_DOMAINS_REQUEST_GET = 'ORGSERVICE_DOMAINS_REQUEST_GET';
export const ORGSERVICE_DOMAINS_RECEIVE_GET_SUCCESS = 'ORGSERVICE_DOMAINS_RECEIVE_GET_SUCCESS';
export const ORGSERVICE_DOMAINS_RECEIVE_GET_FAILURE = 'ORGSERVICE_DOMAINS_RECEIVE_GET_FAILURE';


// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  me: {
    organizationKey: '',
    roles: [],
  },
  domains: [],

  // generic or global properties go here
  isLoading: false,
};

// ------------------------------------
// Selectors
// ------------------------------------
export const organizationIsLoading = (state) => state.organization.isLoading;
export const organizationMe = (state) => state.organization.me;

export const organizationKey = (state) => {
  const me = organizationMe(state);
  return me.organizationKey;
};

export const organizationDomains = (state) => state.organization.domains || [];

export const organizationHasVerifiedDomains = (state) => organizationDomains(state).some((domain) => !domain.token);

// ------------------------------------
// Actions
// ------------------------------------
export const orgServiceMeReceiveGetSuccess = (payload = {}) => ({
  type: ORGSERVICE_ME_RECEIVE_GET_SUCCESS,
  payload,
});

export const orgServiceMeReceiveGetFailure = (payload = {}) => ({
  type: ORGSERVICE_ME_RECEIVE_GET_FAILURE,
  payload,
});

export const orgServiceMeRequestGet = () => (dispatch) => {
  dispatch({
    type: ORGSERVICE_ME_REQUEST_GET,
  });

  return daoOrganizationMeGet()
    .then(
      response => dispatch(orgServiceMeReceiveGetSuccess(response.data)),
      ex => dispatch(orgServiceMeReceiveGetFailure(ex)),
    );
};

// Organization domains
export const orgServiceDomainsReceiveGetSuccess = (payload = {}) => ({
  type: ORGSERVICE_DOMAINS_RECEIVE_GET_SUCCESS,
  payload,
});

export const orgServiceDomainsReceiveGetFailure = (payload = {}) => ({
  type: ORGSERVICE_DOMAINS_RECEIVE_GET_FAILURE,
  payload,
});

export const orgServiceDomainsRequestGet = (orgKey) => (dispatch) => {
  dispatch({
    type: ORGSERVICE_DOMAINS_REQUEST_GET,
  });

  return daoOrganizationDomainsGet(orgKey)
    .then(
      response => dispatch(orgServiceDomainsReceiveGetSuccess(response.data)),
      ex => dispatch(orgServiceDomainsReceiveGetFailure(ex)),
    );
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [ORGSERVICE_ME_REQUEST_GET]: (state) => ({...state, isLoading: true}),
  [ORGSERVICE_ME_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        me: action.payload,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [ORGSERVICE_ME_RECEIVE_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [ORGSERVICE_DOMAINS_REQUEST_GET]: (state) => ({...state, isLoading: true}),
  [ORGSERVICE_DOMAINS_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        domains: action.payload,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [ORGSERVICE_DOMAINS_RECEIVE_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const orgServiceReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default orgServiceReducer;
