import api from 'dao/providers/axios';
import appconfig from 'config/appconfig';

const organizationMeEndpoint = appconfig.endpoints.organizationMe;
const organizationByKeyEndpoint = appconfig.endpoints.organizationByKey;
const organizationDomainsEndpoint = appconfig.endpoints.organizationDomains;
const organizationAdminsEndpoint = appconfig.endpoints.organizationAdmins;

export const daoOrganizationMeGet = () => api.get(organizationMeEndpoint);

export const daoOrganizationByOrganizationKeyGet = (organizationKey) => api.get(organizationByKeyEndpoint(organizationKey));

export const daoOrganizationDomainsGet = (organizationKey) => api.get(organizationDomainsEndpoint(organizationKey));

export const daoOrganizationAdminsGet = (organizationKey) => api.get(organizationAdminsEndpoint(organizationKey));
