import webStorageDetect from 'lib/3rdparty-es5/web-storage-detect';

// web storage polyfill
if (!webStorageDetect()) {
  require('lib/3rdparty-es5/web-storage-polyfill')(); // eslint-disable-line global-require
}

/**
 * Session interface that provides methods for storing, retrieving, and removing session values.
 * The window.sessionStorage class is used by default
 */
export class Session {
  /**
   * @constructor
   * @param {*} [storage=sessionStorage]
   */
  constructor(storage = window.sessionStorage) {
    this.storage = storage;
    this.item = undefined;
    this.prefix = 'lmi_pmp_';
    this.originalTargetKey = 'orig_target';
  }

  /**
   * Returns a string for the key with the prefix used for storage.
   * @param key
   * @returns {string}
   */
  keyName(key) {
    return `${this.prefix}${key}`;
  }

  /**
   * Gets an item from the storage provider by key
   * @param key
   * @returns {string|null}
   */
  getItem(key) {
    return this.storage.getItem(this.keyName(key));
  }

  /**
   * Sets an item in the storage provider using the key and value.
   * The key will have a prefix prepended to it before being stored.
   * @param key
   * @param value
   * @returns {*}
   */
  setItem(key, value) {
    return this.storage.setItem(this.keyName(key), value);
  }

  /**
   * Removes an item registered in the storage provider
   * @param key
   * @returns {*}
   */
  removeItem(key) {
    return this.storage.removeItem(this.keyName(key));
  }

  /**
   * Saves the original target URL from window.location (or a provided override object) in storage provided the following rules are true:
   * - Regex does not match `#access_token` - Prevents the auth redirect flow becoming the destination
   * - the URL is not `/` - Prevents an issue when logging out where `/` is saved as the destination
   * - There is not already an original target in storage - Prevents infinite loops
   * @param {object} [override] An override object {pathname, [search], [hash]} to use in place of window.location
   */
  setOriginalTarget(override) {
    const location = (override && override.hasOwnProperty('pathname')) ? override : window.location;
    const authRegex = /\/#access_token/g;
    const fullPath = `${location.pathname}${location.search || ''}${location.hash || ''}`;
    // only set the target if its not the one provided by the auth redirect AND the full path isn't `/` AND there is not already one set
    if (!fullPath.match(authRegex) && fullPath !== '/' && !this.getOriginalTarget()) {
      this.setItem(this.originalTargetKey, fullPath);
    }
  }

  /**
   * Gets the original target URL from storage or returns null if it hasn't been set
   * @returns {*}
   */
  getOriginalTarget() {
    const target = this.getItem(this.originalTargetKey);
    return (target === 'null') ? null : target;
  }

  /**
   * Removes the original target from storage
   */
  removeOriginalTarget() {
    this.removeItem(this.originalTargetKey);
  }
}

export default new Session();
